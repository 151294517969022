let AppLayout = () => import('../components/AppLayout.vue');
let OAuthLogin = () => import('../components/OAuthLogin.vue');
let DashboardEditor = () => import('../components/DashboardEditor.vue');
let Dashboards = () => import('../components/Dashboards.vue');
let DeviceManager = () => import('../components/DeviceManager.vue');
let Project = () => import('../components/Project.vue');
let Flow = () => import('../components/Flow.vue');
let Walkthrough = () => import('../components/Walkthrough.vue');
let DeviceMap = () => import('../components/DeviceMap.vue');
let PermissionComponent = () => import('../components/PermissionComponent.vue');
let RolesComponent = () => import('../components/RolesComponent.vue');
let OrganizationManagementComponent = () =>
  import('../components/OrganizationManagementComponent.vue');
let OrganizationComponent = () =>
  import('../components/OrganizationComponent.vue');
let AgreementsComponent = () => import('../components/AgreementsComponent.vue');
let DataExplorer = () => import('../components/DataExplorer.vue');
let Notifications = () => import('../components/Notifications.vue');
let ErrorComponent = () => import('../components/ErrorComponent.vue');
let NotFoundComponent = () => import('../components/NotFoundComponent.vue');
let ProductsManagement = () =>
  import('../components/ProductsManagement/ProductsManagement.vue');
let RegisterComponent = () => import('../components/Register.vue');
let VPN = () => import('../components/VPN/VPN.vue');
let DirectAccess = () => import('../components/DirectAccess.vue');
let Log = () => import('../components/Log/Log.vue');
let AdminNotifications = () =>
  import('../components/AdminNotifications/AdminNotifications.vue');
let Alarms = () => import('../components/Alarms.vue');
let AppStore = () => import('../components/Applications/AppStore.vue');
let AppContainer = () => import('../components/Applications/AppContainer.vue');
let Trigger = () => import('../components/Trigger.vue');
let AppInstallationWizard = () => import('../components/Applications/AppInstallationWizard/AppInstallationWizard.vue');

import { CorvinaPages } from '@corvina/corvina-app-connect';
import store from '../store/store';
import { StoreLoader } from '../store/store-loader';
import PermissionAction from '@/constant/PermissionAction';
import PermissionEntity from '@/constant/PermissionEntity';
import { RouteConfig } from 'vue-router';

/**
 * This is a mapping of CorvinaPages to routes. 
 * If an app installed in the store, ask to navigate to a CorvinaPage, this mapping will be used to determine the route to navigate to.
 * In this way, we are free to change the route names in the future without breaking the app.
 */
export const CorvinaPagesToRoutes = {
  [CorvinaPages.HOME]: '/home',
  [CorvinaPages.DASHBOARD]: '/flows',
  [CorvinaPages.DEVICE_ACTIVATE]: '/walkthrough',
  [CorvinaPages.DEVICE_MANAGE]: '/devices',
  [CorvinaPages.DEVICE_VPN]: '/vpn',
  [CorvinaPages.DATA_CONFIGURE]: '/devicemap',
  [CorvinaPages.DATA_EXPLORE]: '/data-explore',
  [CorvinaPages.DATA_ALARMS]: '/alarms',
  [CorvinaPages.DATA_NOTIFICATIONS]: '/notifications',
  [CorvinaPages.LOG]: '/log',
  [CorvinaPages.IAM_ORGANIZATIONS]: '/organizations',
  [CorvinaPages.IAM_USERS]: '/users',
  [CorvinaPages.IAM_ROLES]: '/roles',
  [CorvinaPages.DEALER]: '/dealer',
  'trigger': '/trigger'
};

function makeOauthRedirectCompliantPath(pathPattern) {
  return pathPattern; // + '(\\&.*)*'
}

const portalAccessPermission = [[PermissionAction.ACCESS], [PermissionEntity.PORTAL]];

export interface RouteConfigAuth {
  iotOnly?: boolean;
  vpnOnly?: boolean;
  permissions?: [PermissionAction[], PermissionEntity[]] [];
}

const routes : RouteConfig[] = [
  // {
  //   path: '/',
  //   component: Login,
  //   redirect: '/login',
  //   children: [
  //     {
  //       path: 'login',
  //       name: 'login',
  //       component: Login,
  //       meta: {
  //         breadcrumb: 'Login'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/',
    component: AppLayout,
    redirect: '/home',
    meta: {
      breadcrumb: ''
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.HOME],
    name: 'home',
    component: AppLayout,
    meta: {
      breadcrumb: ''
    },
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadPermissionStores();
      next();
    }
  },
  {
    path: '/directaccess/',
    component: AppLayout,
    meta: {
      breadcrumb: 'directaccess'
    },
    children: [
      {
        path: '',
        name: 'directaccess',
        component: DirectAccess,
        meta: {
          breadcrumb: '/'
        },
        children: [
          {
            path: 'dashboards',
            name: 'directaccess-dashboards',
            component: DirectAccess,
            meta: {
              breadcrumb: '/'
            }
          },
          {
            path: 'applications',
            name: 'directaccess-applications',
            component: DirectAccess,
            meta: {
              breadcrumb: '/'
            }
          },
          {
            path: 'flows',
            name: 'directaccess-flows',
            component: DirectAccess,
            meta: {
              breadcrumb: '/'
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDashboardsStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: '/register',
    component: RegisterComponent,
    meta: {
      breadcrumb: 'register',
      requiresAuth: false
    },
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadBaseUIStores();
      await StoreLoader.loadNotificationStores();
      next();
    }
  },
  {
    path: '/login',
    name: 'login',
    component: OAuthLogin,
    meta: {
      breadcrumb: '/login'
    },
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadVPNStores();
      next();
    }
  },
  {
    path: '/organization/',
    name: 'organization',
    component: OrganizationComponent,
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadPermissionStores();
      await StoreLoader.loadBaseUIStores();
      next();
    }
  },
  {
    path: '/agreements/',
    component: AppLayout,
    meta: {
      breadcrumb: 'agreements'
    },
    children: [
      {
        path: '',
        name: 'agreements',
        component: AgreementsComponent,
        meta: {
          breadcrumb: '/'
        }
      }
    ]
  },
  {
    path: makeOauthRedirectCompliantPath(
      CorvinaPagesToRoutes[CorvinaPages.DASHBOARD]
    ),
    component: AppLayout,
    meta: {
      breadcrumb: 'flows'
    },
    children: [
      {
        path: '',
        name: 'flows',
        component: Dashboards,
        meta: {
          breadcrumb: '/',
          auth: { 
            iotOnly: true, 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.MODELS]], 
              [[PermissionAction.READ], [PermissionEntity.DASHBOARDS]] 
            ] 
          }
        },
        children: [
          {
            path: 'dashboards',
            name: 'dashboards',
            component: Dashboards,
            meta: {
              breadcrumb: '/',
              auth: { 
                iotOnly: true, 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.MODELS]], 
                  [[PermissionAction.READ], [PermissionEntity.DASHBOARDS]] 
                ] 
              }
            }
          },
          {
            path: 'widgets',
            name: 'widgets',
            component: Dashboards,
            meta: {
              breadcrumb: '/',
              auth: { 
                iotOnly: true, 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.MODELS]], 
                  [[PermissionAction.READ], [PermissionEntity.DASHBOARDS]] 
                ] 
              }
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDashboardsStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: '/dashboards/:projectId',
    component: AppLayout,
    meta: {
      breadcrumb: 'dashboards/:projectId'
    },
    children: [
      {
        path: '',
        name: 'dashboard-viewer',
        component: Project,
        props: route => {
          /*console.log(route);*/ return {
            storePreloaded: false,
            projectId: route.params.projectId,
            version: route.query.version,
            deviceSlots: extractDeviceSlotsFromQuery(route.query)
          };
        },
        meta: {
          breadcrumb: 'dashboard-viewer'
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDashboardsStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: makeOauthRedirectCompliantPath('/flow/:flowId/:dashboardFlowId?'),
    component: AppLayout,
    meta: {
      breadcrumb: 'flow/:flowId/:dashboardFlowId?'
    },
    children: [
      {
        path: '',
        name: 'flow-viewer',
        component: Flow,
        props: route => {
          // console.log(route);
          return {
            storePreloaded: false,
            flowId: route.params.flowId,
            dashboardFlowId: route.params.dashboardFlowId,
            version: route.query.version,
            deviceSlots: extractDeviceSlotsFromQuery(route.query)
          };
        },
        meta: {
          breadcrumb: 'flow-viewer'
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDashboardsStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: makeOauthRedirectCompliantPath('/dashboards/:projectId/edit'),
    component: AppLayout,
    meta: {
      breadcrumb: 'dashboards/:projectId'
    },
    children: [
      {
        path: '',
        name: 'dashboard-editor',
        component: DashboardEditor,
        props: route => {
          /*console.log(route);*/ return {
            storePreloaded: false,
            projectId: route.params.projectId,
            version: route.query.version
          };
        },
        meta: {
          breadcrumb: 'dashboard-editor'
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDashboardsStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: '/dashboards/:projectId/new',
    component: AppLayout,
    meta: {
      breadcrumb: 'dashboards/:projectId'
    },
    children: [
      {
        path: '',
        name: 'dashboard-editor-new',
        component: DashboardEditor,
        props: route => {
          /*console.log(route);*/ return {
            storePreloaded: true,
            projectId: route.params.projectId
          };
        },
        meta: {
          breadcrumb: 'dashboard-editor'
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDashboardsStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.DEVICE_VPN],
    component: AppLayout,
    redirect: '/vpn',
    name: 'vpn',
    meta: {
      breadcrumb: 'Devices',
      auth: { 
        vpnOnly: true, 
        permissions: [ 
          portalAccessPermission, 
          [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
        ] 
      }
    },
    children: [
      {
        path: '/',
        name: 'vpn-devices',
        component: VPN,
        meta: {
          breadcrumb: '/',
          auth: { 
            vpnOnly: true, 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
            ] 
          }
        },
        children: [
          {
            path: 'endpoints',
            name: 'vpn-endpoints',
            component: VPN,
            meta: {
              breadcrumb: '/',
              auth: { 
                vpnOnly: true, 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
                ] 
              }
            }
          },
          {
            path: 'applications',
            name: 'vpn-applications',
            component: VPN,
            meta: {
              breadcrumb: '/',
              auth: { 
                vpnOnly: true, 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
                ] 
              }
            }
          },
          {
            path: 'profiles',
            name: 'vpn-profiles',
            component: VPN,
            meta: {
              breadcrumb: '/',
              auth: { 
                vpnOnly: true, 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
                ] 
              }
            }
          }
        ],
        beforeEnter: async (to, from, next) => {
          await StoreLoader.loadBaseUIStores();
          await StoreLoader.loadVPNStores();
          await StoreLoader.loadDeviceStores();
          await StoreLoader.loadNotificationStores();
          store.dispatch('frame/showTopbar');
          next();
        }
      }
    ]
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.DEVICE_MANAGE],
    component: AppLayout,
    redirect: '/devices',
    meta: {
      breadcrumb: 'Devices'
    },
    children: [
      {
        path: '/',
        name: 'devices',
        component: DeviceManager,
        meta: {
          breadcrumb: '/',
          auth: { 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
            ] 
          }
        },
        children: [
          {
            path: 'groups',
            name: 'devices-groups',
            component: DeviceManager,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.DEVICES]],
                  [[PermissionAction.READ], [PermissionEntity.DEVICEGROUPS]] 
                ] 
              }
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      await StoreLoader.loadNotificationStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.DEVICE_ACTIVATE],
    component: AppLayout,
    redirect: '/walkthrough',
    meta: {
      breadcrumb: 'Walkthrough'
    },
    children: [
      {
        path: '/',
        name: 'walkthrough',
        component: Walkthrough,
        meta: {
          breadcrumb: '/',
          auth: { 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.MODELS]], 
              [[PermissionAction.CREATE], [PermissionEntity.DEVICES]] 
            ] 
          }
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: '/devicemap',
    component: AppLayout,
    redirect: '/devicemap',
    meta: {
      breadcrumb: 'DeviceMap'
    },
    children: [
      {
        path: '/',
        name: 'devicemap',
        component: DeviceMap,
        meta: {
          breadcrumb: '/',
          auth: { 
            iotOnly: true, 
            permissions: [ 
              portalAccessPermission,  
              [[PermissionAction.READ], [PermissionEntity.MODELS]], 
              [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
            ] 
          },
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: '/permission',
    component: AppLayout,
    redirect: '/permission',
    meta: {
      breadcrumb: 'Permission'
    },
    children: [
      {
        path: '/',
        name: 'permission',
        component: PermissionComponent,
        meta: {
          breadcrumb: '/',
          auth: { 
            permissions: [ 
              portalAccessPermission,  
              [[PermissionAction.READ], [PermissionEntity.ROLES, PermissionEntity.USERS, PermissionEntity.USERGROUPS]] 
            ] 
          }
        },
        children: [
          {
            path: 'groups',
            name: 'permission-groups',
            component: PermissionComponent,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission,  
                  [[PermissionAction.READ], [PermissionEntity.ROLES, PermissionEntity.USERS, PermissionEntity.USERGROUPS]] 
                ] 
              }
            }
          },
          {
            path: 'roles',
            name: 'permission-roles',
            component: PermissionComponent,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission,  
                  [[PermissionAction.READ], [PermissionEntity.ROLES, PermissionEntity.USERS, PermissionEntity.USERGROUPS]] 
                ] 
              }
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDashboardsStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.IAM_ROLES],
    component: AppLayout,
    redirect: '/roles',
    meta: {
      breadcrumb: 'Roles'
    },
    children: [
      {
        path: '/',
        name: 'roles',
        component: RolesComponent,
        meta: {
          breadcrumb: '/',
          auth: { 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.ROLES]] 
            ] 
          }
        },
        children: [
          {
            path: 'data',
            name: 'roles-application',
            component: RolesComponent,
            meta: {
              breadcrumb: '/',
              auth: {
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.ROLES]] 
                ]
              }
            }
          },
          {
            path: 'device',
            name: 'roles-device',
            component: RolesComponent,
            meta: {
              breadcrumb: '/',
              auth: {
                  permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.ROLES]] 
                ] 
              }
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadVPNStores();
      await StoreLoader.loadBaseUIStores();
      await StoreLoader.loadFrameStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.IAM_ORGANIZATIONS],
    component: AppLayout,
    redirect: '/organizations',
    meta: {
      breadcrumb: 'Organizations'
    },
    children: [
      {
        path: '/',
        name: 'organizations',
        component: OrganizationManagementComponent,
        meta: {
          breadcrumb: '/',
          auth: { 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.ORGANIZATIONS]] 
            ] 
          }
        },
        children: [
          {
            path: 'resources',
            name: 'organizations-resources',
            component: OrganizationManagementComponent,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.ORGANIZATIONS]] 
                ] 
              }
            }
          },
          {
            path: 'consumptions',
            name: 'organizations-consumptions',
            component: OrganizationManagementComponent,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.ORGANIZATIONS]] 
                ] 
              }
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadPermissionStores();
      await StoreLoader.loadBaseUIStores();
      await StoreLoader.loadFrameStores();
      await StoreLoader.loadVPNStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.DATA_EXPLORE],
    component: AppLayout,
    redirect: '/data-explore',
    meta: {
      breadcrumb: 'Data Explore'
    },
    children: [
      {
        path: '/',
        name: 'data-explore',
        component: DataExplorer,
        meta: {
          breadcrumb: '/',
          auth: {
            iotOnly: true, 
            permissions: [ 
              portalAccessPermission,  
              [[PermissionAction.READ], [PermissionEntity.MODELS]], 
              [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
            ] 
          }
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.DATA_NOTIFICATIONS],
    component: AppLayout,
    redirect: '/notifications',
    meta: {
      breadcrumb: 'Notifications'
    },
    children: [
      {
        path: '/',
        name: 'notifications',
        component: Notifications,
        meta: {
          breadcrumb: '/',
          auth: { 
            iotOnly: true, 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.NOTIFICATIONS]] 
            ] 
          }
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadNotificationStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: '/store',
    component: AppLayout,
    redirect: '/store',
    meta: {
      breadcrumb: 'Store'
    },
    children: [
      {
        path: '/',
        name: 'store',
        component: AppStore,
        meta: {
          breadcrumb: '/',
          auth: {
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.ORGANIZATIONS]] 
            ]
          }
        }
      },
      {
        path: 'manage',
        name: 'store-manage',
        component: AppStore,
        meta: {
          breadcrumb: '/',
          auth: {
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.ORGANIZATIONS]] 
            ]
          }
        }
      },
      {
        path: 'container/:id',
        props: true,
        name: 'app-container',
        component: AppContainer,
        meta: {
          breadcrumb: '/container'
        },
      },
      {
        path: 'install/:appId',
        props: true,
        name: 'app-install',
        component: AppInstallationWizard,
        meta: {
          breadcrumb: '/install'
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      await StoreLoader.loadNotificationStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.DEALER],
    component: AppLayout,
    redirect: '/dealer',
    meta: {
      breadcrumb: 'Dealer'
    },
    children: [
      {
        path: '/',
        name: 'dealer',
        component: ProductsManagement,
        meta: {
          breadcrumb: '/',
          auth: { 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.CREATE, PermissionAction.UPDATE], 
              [PermissionEntity.PRODUCTS]] 
            ] 
          }
        },
        children: [
          {
            path: 'clients',
            name: 'dealer-clients',
            component: ProductsManagement,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.CREATE, PermissionAction.UPDATE], 
                  [PermissionEntity.PRODUCTS]] 
                ] 
              }
            }
          },
          {
            path: 'licenses',
            name: 'dealer-licenses',
            component: ProductsManagement,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.CREATE, PermissionAction.UPDATE], 
                  [PermissionEntity.PRODUCTS]] 
                ] 
              }
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      await StoreLoader.loadBaseUIStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes[CorvinaPages.LOG],
    component: AppLayout,
    redirect: '/log',
    meta: {
      breadcrumb: 'log'
    },
    children: [
      {
        path: '/',
        name: 'log',
        component: Log,
        meta: {
          breadcrumb: '/',
          auth: { 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.ACCESS], [PermissionEntity.AUDIT]], 
              [[PermissionAction.READ], [PermissionEntity.ROLES]] 
            ] 
          }
        },
        children: [
          {
            path: 'failed-notifications',
            name: 'log-failed-notifications',
            component: Log,
            meta: {
              breadcrumb: '/',
              auth: { 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.ACCESS], [PermissionEntity.AUDIT]], 
                  [[PermissionAction.READ], [PermissionEntity.ROLES]] 
                ] 
              }
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadAuditStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: '/admin/notifications',
    component: AppLayout,
    redirect: '/admin/notifications',
    meta: {
      breadcrumb: '/admin/notifications'
    },
    children: [
      {
        path: '/',
        name: 'adminnotifications',
        component: AdminNotifications,
        meta: {
          breadcrumb: '/',
          auth: { 
            iotOnly: true, 
            permissions: [ 
              portalAccessPermission,
              [[PermissionAction.READ, PermissionAction.UPDATE], [PermissionEntity.ORGANIZATIONS]] 
            ]
          }
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadFrameStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },

  {
    path: CorvinaPagesToRoutes[CorvinaPages.DATA_ALARMS],
    component: AppLayout,
    redirect: '/alarms',
    meta: {
      breadcrumb: 'Alarms',
    },
    children: [
      {
        path: '/',
        name: 'alarms',
        component: Alarms,
        meta: {
          breadcrumb: '/',
          auth: { 
            iotOnly: true, 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
            ] 
          },
        },
        children: [
          {
            path: 'alarms-history',
            name: 'alarms-history',
            component: Alarms,
            meta: {
              breadcrumb: '/',
              auth: { 
                iotOnly: true, 
                permissions: [ 
                  portalAccessPermission, 
                  [[PermissionAction.READ], [PermissionEntity.DEVICES]] 
                ] 
              },
            }
          }
        ]
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  {
    path: CorvinaPagesToRoutes['trigger'],
    component: AppLayout,
    redirect: '/trigger',
    meta: {
      breadcrumb: 'Trigger'
    },
    children: [
      {
        path: '/',
        name: 'Trigger',
        component: Trigger,
        meta: {
          breadcrumb: '/',
          auth: { 
            iotOnly: true, 
            permissions: [ 
              portalAccessPermission, 
              [[PermissionAction.READ], [PermissionEntity.ORGANIZATIONS]] 
            ] 
          }
        }
      }
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadTriggerStores();
      store.dispatch('frame/showTopbar');
      next();
    },
  },
  {
    path: '/404',
    component: AppLayout,
    redirect: '/404',
    meta: {
      breadcrumb: 'Not Found'
    },
    children: [
      {
        path: '/',
        name: '404',
        component: NotFoundComponent,
        meta: {
          breadcrumb: '/'
        }
      },
    ],
    beforeEnter: async (to, from, next) => {
      await StoreLoader.loadDeviceStores();
      await StoreLoader.loadNotificationStores();
      store.dispatch('frame/showTopbar');
      next();
    }
  },
  // {path: '*', component: NotFound}
  {
    path: '/',
    name: 'error',
    component: ErrorComponent
  }
];

export default routes;

export const routeMap = new Map<string, RouteConfig>();

const mapRoutes = (route: RouteConfig) => {
  if (route.name) {
    routeMap.set(route.name, route);
  }
  if (route.children) {
    route.children.forEach(child => {
      mapRoutes(child);
    });
  }
}

routes.forEach(route => {
  mapRoutes(route);
});


function extractDeviceSlotsFromQuery(query) {
  let deviceSlots = {};
  query = query || {};
  for (let key in query) {
    if (key.indexOf('ecc_device_') != -1) {
      deviceSlots[key.split('ecc_device_')[1]] = query[key];
    }
  }
  return deviceSlots;
}

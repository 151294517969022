// @ts-ignore : ignore BrandData import since it needs to be resolved at runtime
import { defaultLocale } from "BrandData";

export function getDomain() : string|null {
  const dp = document.location.hostname.split('.')
  let domain = null;
  if(dp.length >= 2) {
      domain = '.' + dp[dp.length-2] + '.' + dp[dp.length-1];
  }
  return domain;
}

export function setCC2LocaleCookie(locale: string) {
  const domain = getDomain();
  if(domain) {
    // domain and path must be set. ".domain": the dot is mandatory to share between subdomains.
    document.cookie = `cc2locale=${locale};domain=${domain};path=/;`
  }
}

export const fixShortLocale = (locale: string) => {
  if (locale.length < 3) {
    switch(locale) {
      case 'en':
        return defaultLocale;
      default:
        return `${locale}-${locale.toUpperCase()}`        
    }
  }
  return locale;
}

export function formatBytes(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

// @ts-ignore : ignore BrandData import since it needs to be resolved at runtime
import { messages } from "BrandData";
export async function setLocale(i18n, host: string, newLocale: string) {
  // always load the fallback locale
  // otherwise different languages cannot fallback and frontend shows keypath instead
  await ensureTextLoaded(i18n, host, i18n.fallbackLocale);
  
  const locale = fixShortLocale(newLocale);
  await ensureTextLoaded(i18n, host, locale);
  i18n.locale = locale;
}

async function ensureTextLoaded(i18n, host: string, locale: string) {
  if (!i18n.messages[locale]) {
    const texts = await messages[locale](host);
    i18n.setLocaleMessage(locale, texts);
  }
}